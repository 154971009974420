.print {
  width: 310mm;
  height: 296mm;
  padding: 20mm;
  background: #FFFFFF;
  // margin: 0 auto;
  section {
    width: 100%;
  }
  .registration {
    section .ant-row {
      margin-bottom: 2mm;
    }
  }
}

@media print {
  .ant-table {
    font-size: 18px !important;
  }

  td, td * {
    color: black !important;
  }

  aside, header, footer, button, input, [class*="breadcrumbs"], .ant-alert, .ant-pagination, .ant-message, .ant-checkbox-wrapper, .ant-btn-group, .ant-calendar-picker, .ant-menu, .ant-input-affix-wrapper, .ant-table-column-sorter, .ant-dropdown-trigger, .ant-menu-submenu, .ant-menu-submenu-popup, .anticon-more, .anticon-setting, .ant-picker {
    display: none !important;
  }

  .utils__content, .print {
    display: block !important;
  }

  body {
    background-color: white;
  }

  .ant-card-bordered {
    border: none !important;
  }

  .print-wrap {
    flex-wrap: wrap;
  }
}